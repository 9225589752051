import React, { useEffect } from 'react';
import logo from "./../../assets/images/Logo.png";
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const LoginFirstToBePro = () => {
    const token = localStorage.getItem("_token")
    console.log("localstorage", )
    const navigate = useNavigate()
    const handleNavigate = ()=>{
        navigate("/signin")
    }
useEffect(()=>{
if(token){
    navigate("/", { state: { fromLogin: true } })
}
},[])
   
  return (
    <div style={{
        width: "100%",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: "20px",
        padding: "20px"
    }}>
        <img style={{ width: "280px", height: "auto" }} src={logo} alt="Logo" />
        <div style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "10px"
        }}>
            <p style={{
                textAlign: "center",
                fontSize: "1.7rem", // Adjust font size here
                fontWeight: "600",
                color: "#DD417A"
            }}>Become Pro</p>
            <p style={{
                textAlign: "center",
                fontSize: "1.5rem", // Adjust font size here
                color: "#234b68"
            }}>Please log in first to become a pro. <span onClick={()=>handleNavigate()} style={{cursor:"pointer"}}className="text-lg text-[var(--main-color-1)]">Go To Login Page </span></p>
        </div>
    </div>
  );
}

export default LoginFirstToBePro;
