import axios, { Axios } from "axios";
import { jwtDecode } from "jwt-decode";
import { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

function ChangePassword({ info }) {
    const [pass, setPass] = useState("");
    const [newPass, setNewPass] = useState("");
    const auth = useSelector(state=>state.auth)
    const decodeJWT = (token) => {
        try {
          // The jwtDecode function will throw an error if the token is invalid
          return jwtDecode(token);
        } catch (err) {
          console.error('Failed to decode JWT:', err);
          return null; // Return null if token is invalid or corrupted
        }
      };
    const changePass = async () => {
        try {
          const token = localStorage.getItem("_token");
          if (!token) {
            toast.error("No token found, please login again.");
            return; // Exit if no token is found
          }
          const response = await axios({
            method: "post",
            url: `${process.env.REACT_APP_BACKEND_URL}/user/changePassword`,
            headers: {
              Authorization: `Bearer ${token}`,
            },
            data: {
              _id:  decodeJWT(auth?.user?.accessToken)?.user?._id,
              password: pass,
              new_password: newPass,
            },
          });
      
          if (response.data.code === 200) {
            toast.success("Password Changed Successfully");
          } else {
            toast.error(response.data.message);
          }
        } catch (err) {
          // More detailed error handling
          const errMsg = err.response?.data?.message || err.message || "An error occurred while changing password";
          toast.error(errMsg);
          console.error("Error during password change:", err);
        }
      };
   
    return (
      <div className="w-full py-5 px-5 bg-[#fff] rounded-[20px] mt-20">
        <h1 className="h-fit w-full border-b-2 border-gray-300 pb-[20px]">
          Change Password
        </h1>
        <div className="p-2 flex items-start content-start w-full">
          <div className="w-1/2 p-2">
            <h5 className="text-pink-600 font-semibold">Old Password</h5>
            <input
              type="password"
              className="p-2 rounded-md border-2 border-gray-300 text-gray-500 w-full"
              onChange={(e) => setPass(e.target.value)}
            />
          </div>
          <div className="w-1/2 p-2">
            <h5 className="text-pink-600 font-semibold">New Password</h5>
            <input
              type="text"
              className="p-2 rounded-md border-2 border-gray-300 text-gray-500 w-full"
              onChange={(e) => setNewPass(e.target.value)}
            />
          </div>
        </div>
        <div className="ml-4">
          <button
            className="text-white bg-pink-600 justify-self-end align-self-end self-end p-4 rounded-lg"
            onClick={() => changePass()}>
            Change Password
          </button>
        </div>
      </div>
    );
  }
  

  export default ChangePassword