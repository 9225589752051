import { SET_VISIBLE_SPINNER } from "../type"

const initialState = {
  visibleSpinner: false
}

const userReducer = (state = initialState, action) => {
  const { type, payload } = action

  switch(type) 
  {
    case SET_VISIBLE_SPINNER:
      return {
        ...state,
        visibleSpinner: payload
      }
    default:
      return state;
  }
}

export default userReducer